$('#galery').galereya();
(function($) {
    $('.js-nav-menu-toggle').on('click', function() {
      $(this).parents('.navigation-menu').toggleClass('navigation-menu--open');
      if($(this).parents('.navigation-menu').hasClass('navigation-menu--open')) {
        $('.site-title').css('display','none');
      }else{
        $('.site-title').css('display','block');
      }
    });
    
    $('html').on('click', function(e) {
      if(!$(e.target).closest('.js-nav-menu').length &&
        ($('.js-nav-menu').hasClass('navigation-menu--open'))) {
          $('.js-nav-menu').removeClass('navigation-menu--open');
      }
    });
})(jQuery);
$(document).ready(function() {
    // Variable to hold request
    var request;

    $(document).on('click', '.view-photo', function(event) {           
        // Prevent default event of the button
        event.preventDefault();

        var windowWidth = $(window).width();
        var dialogWidth = windowWidth * 0.8;
        var windowHeight = $(window).height();
        var dialogHeight = windowHeight * 0.8;
        var photoContainer = $(this).find('img');
        var dialog = document.getElementById('photo_dialog');
        var photoID = photoContainer.data('id');

        // Get the action of the form
        var action = 'image.php';

        // Fire off the request
        request = $.ajax({
            url: action,
            type: 'post',    
            datatype: 'json',
            data: {pid: photoID}
        });

        // Callback handler that will be called on success
        request.done(function (response, textStatus, jqXHR){
            var content = '';
            if(response){
                const repos = JSON.parse(response); 
                if ( repos != null ) {
                    content += '<div class="repo-link">Purchase on: <ul>';
                    repos.forEach(repo => { 
                        if ( repo.reponame === 'Alamy' ) {
                            content += '<li><a href="' + repo.url + repo.stockguid + '" target="_blank">' + repo.reponame + '</a></li>';
                        }else{
                            content += '<li><a href="' + repo.url + repo.stockid + '" target="_blank">' + repo.reponame + '</a></li>';
                        }
                    });
                    content += '</ul>';
                    content += 'Contact for prints: <ul><li><a href="mailto:mario@m3e.ca">mario@m3e.ca</a></li></ul></div>';
                }else{
                    content += '<div class="repo-link">Contact for prints: <ul><li><a href="mailto:mario@m3e.ca">mario@m3e.ca</a></li></ul></div>';
                }
            }

            content += $(photoContainer)[0].outerHTML;
            dialog.innerHTML = content;

            // Set dialog options
            photodialog.dialog({
                title: photoContainer.attr('alt'),
                overlay: { opacity: 0.1, background: "black" },
                width: dialogWidth,
                height: dialogHeight,
                draggable: false,
                resizable: false,
                modal: true
            });           

            // Open dialog box
            photodialog.dialog( "open" );
        });

        // Callback handler that will be called on failure
        request.fail(function (jqXHR, textStatus, errorThrown){
            // Log the error to the console
            console.error(
                "The following error occurred: "+
                textStatus, errorThrown
            );
        });

        // Callback handler that will be called regardless
        // if the request failed or succeeded
        request.always(function () {
            // Reenable the inputs
            //$inputs.prop("disabled", false);
        });
        
    });
    // Initialise the photo dialog window
    photodialog = $( "#photo_dialog" ).dialog({
        autoOpen: false,
    });
});  